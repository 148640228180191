<template>
  <div class="history-fr">
    <SearchBarFr />
    <div class="container-fr">
      <RingLoading v-if="searchLoading" />
      <div ref="freventcard" class="event-card" v-for="(event, index) in frEventList" :key="event.id">
        <FrEventCard :event="event" :index="index" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'HistoryFr',
  components: {
    SearchBarFr: () => import('@/components/History/SearchBarFr.vue'),
    FrEventCard: () => import('@/components/History/HistoryContent/EventBoard/FrEventCard.vue'),
    RingLoading: () => import('@/components/Base/RingLoading.vue'),
  },
  data() {
    return {
      fullPage: false,
    }
  },
  computed: {
    ...mapState('historyFr', ['frEventList', 'searchLoading', 'frFocusIndex']),
  },
  watch: {
    frFocusIndex() {
      this.$nextTick(() => {
        const el = this.$refs.freventcard[this.frFocusIndex]
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    },
  },
  mounted() {
    this.getFrTagList()
    this.updateHistoryEventModalType('fr')
  },
  beforeDestroy() {
    this.resetHistoryFrData()
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalType']),
    ...mapActions(['getFrTagList']),
    ...mapActions('historyFr', ['resetHistoryFrData']),
    onCancel() {},
  }
}
</script>

<style lang="scss" scoped>
.history-fr {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  .container-fr {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    box-sizing: border-box;
    background: #282942;
    position: relative;
  }
}
</style>